import { graphql } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import * as C from '../components'
import {
  AddToCart, Details,
  Images,
  Info,
  Related,
  Story
} from '../product'
import * as S from '../styles'

const Mobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`

const Desktop = styled.div`
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
`

export const ProductsQ = graphql`
  query($slug: String) {
    allContentfulProduct(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
`

const Product: React.SFC<any> = props => {
  const product = props.data.allContentfulProduct.edges[0].node
  const soldOut = product.soldOut
  const { images } = product

  return (
    <C.App headerTheme="dark">
      <S.Section className="intro product-intro">
        <S.Wrapper>
          <Mobile>
            <Info product={product} />
            <Images images={images} />
            <Details product={product} />
            <AddToCart
              variantId={product.shopify_product[0].variantId}
              soldOut={soldOut}
            />
          </Mobile>
          <Desktop>
            <S.Grid>
              <S.GridItem
                width="48%"
                breakpoints={{
                  767: '100%',
                }}>
                <Info product={product} />
                <Details product={product} />
                <AddToCart
                  variantId={product.shopify_product[0].variantId}
                  soldOut={soldOut}
                />
                {!product.library}
              </S.GridItem>
              <S.GridItem
                width="48%"
                breakpoints={{
                  767: '100%',
                }}>
                <Images
                  images={images.reduce(
                    (x, y) => (x.includes(y) ? x : [...x, y]),
                    []
                  )}
                />
              </S.GridItem>
            </S.Grid>
          </Desktop>
        </S.Wrapper>
      </S.Section>
      <Story />
      <C.JoinCommunity />
    </C.App>
  )
}

export default Product
